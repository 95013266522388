import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby" // to query for image data

import Layout from "../components/layout"
import SEO from "../components/seo"

const Projects = () => {
  const data = useStaticQuery(graphql`
  query {
    borderlands: file(relativePath: { eq: "borderlands.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    continent: file(relativePath: { eq: "continent-divided.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)
  return (
    <Layout pageTitle="Digital Humanities Projects">
      <SEO title="Digital Humanities Projects" />
      <div className="row pb-5">
        <div className="col-lg-6">
          <h2 className="pb-3">A Continent Divided: The U.S.-Mexico War</h2>
          <p>Perhaps my biggest long-term project as director of the Center for Greater Southwestern Studies at UT Arlington has been the digitization of the UT Arlington Library’s archival holdings on the U.S.-Mexico War, long recognized as one of the most extensive collections on the 1846-1848 conflict. Created and maintained by the library’s Digital Creation department, this website includes manuscript collections, pamphlets, maps, as well as more than 500 Mexican broadsides, some of which have been translated into English. The site also includes contextual materials, such as essays and biographies of major participants.</p>
          <a href="https://library.uta.edu/usmexicowar/" className="btn btn-primary btn mt-4 mb-4" target="_blank" rel="noreferrer">Visit Site</a>
        </div>
        <div className="col-lg-6">
          <a href="https://library.uta.edu/usmexicowar/" target="_blank" rel="noreferrer">
          <Img className="img-responsive" fluid={data.continent.childImageSharp.fluid} alt="" />

            </a>
        </div>
        <hr />
        <div className="col-lg-6">
          <h2 className="pb-3">Border Land: The Struggle for Texas, 1821-46</h2>
          <p>This project is an outgrowth of my current research on early nineteenth century Texas. The goal is to map all sites of violence involving Native Americans, Anglo-Americans, and Mexicans during a 25 year period. This is an ongoing collaborative project between the Center for Greater Southwestern Studies and the UT Arlington Library’s Digital Creation department, funded by the College of Liberal Arts and the Sumerelee Foundation.</p>
          <a href="https://library.uta.edu/borderland/" className="btn btn-primary btn mt-4 mb-4" target="_blank" rel="noreferrer">Visit Site</a>
        </div>
        <div className="col-lg-6">
          <a href="https://library.uta.edu/borderland/" target="_blank" rel="noreferrer">
          <Img className="img-responsive" fluid={data.borderlands.childImageSharp.fluid} alt="" />
            </a>
        </div>
        <hr />
      </div>
    </Layout>
  )
}

export default Projects
